<template>
  <section id="home" class="hero-section min-vh-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-7">
          <div class="hero-content">
            <h1 class="wow fadeInUp" data-wow-delay=".2s">Règlement général sur la protection des données</h1>
          </div>
        </div>
        <div class="col-lg-12">
          <div style="color: black;">
            <p class="mt-5 mb-5">
              L’obligation d’information existe déjà depuis quelques temps dans la loi Informatique et Libertés. Elle a cependant été renforcée par le <a style="color: black" href="https://www.cnil.fr/"><strong>RGPD</strong></a> depuis le 25 mai 2018. L’information doit maintenant être plus complète et plus précise.
            </p>
            <p class="mt-5">
              Cette transparence permet aux personnes concernées :
            </p>
            <ul class="list-group mt-2">
              <li class="list-group-item bg-transparent">
                - de connaître la raison de la collecte des différentes données les concernant
              </li>
              <li class="list-group-item bg-transparent">
                - de comprendre le traitement qui sera fait de leurs données
              </li>
              <li class="list-group-item bg-transparent">
                - d’assurer la maîtrise de leurs données, en facilitant l’exercice de leurs droits
              </li>
            </ul>
            <p class="mt-5 mb-5">
              Pour WHAT A FIX, cette transparence contribue à un traitement loyal des données et permet d’instaurer une relation de confiance avec les personnes concernées
            </p>
            <p class="mt-5 mb-5">
              C’est pour cette raison que WHAT A FIX et ses solutions ont toujours été en accord avec la nouvelle régulation, avant même sa mise en place officielle. Ainsi,&nbsp;nos utilisateurs et partenaires sont assurés que leurs données bénéficient de toute la protection et du plus grand soin nécessaires !
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
